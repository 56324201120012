import React, { useState, useEffect, useContext } from "react";
import "./Information.css";
import { Col, Container, Form, Row, Breadcrumb } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { RegxExpression } from "../../../../utils/common";
import { UserGet, UserEdit } from "../../../../utils/apis/myAccount/myAccount";
import Common_Button from "../../../Common-Component/Common_Button/Common_Button";
import UpdateSuccess from "../../../Common_modal/UpdateSuccess/UpdateSuccess";
import { Context } from "../../../../utils/context";
import MyAccount from "../../MyAccount";

function Information() {
  const [editStatus, setEditStatus] = useState(true);
  const { postData, getData, IMG_URL, getDimension, setUserData } =
    useContext(Context);
  const navigate = useNavigate();
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    watch,
    formState: { errors },
    reset,
    trigger,
  } = useForm();

  const hideModalWithDelay = () => {
    setTimeout(() => {
      window.location.href = "/account-information"; // Change window location to root route ("/")
      setModalShow(false);
    }, 3000); // Change the delay time as needed (3000 milliseconds = 3 seconds)
  };

  const [modalShow, setModalShow] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [profileImage, setProfileImage] = useState(null);

  const imageFile = watch("image");

  const onSubmit = async (data) => {
    const finalData = new FormData();
    finalData.append("first_name", data?.first_name);
    finalData.append("contact_no", data?.contact_no);
    finalData.append("dob", data?.dob);
    finalData.append("email", data?.email);
    finalData.append("image", data?.image[0]);
    const res = await UserEdit(finalData);
    if (res?.success) {
      setModalShow(true);
      setTimeout(() => {
        setModalShow(false);
        setEditStatus(true);
      }, 3000);
    } else {
      if (res?.message?.both) {
        setError("email", { message: res?.message?.both });
        setError("contact_no", { message: "" });
      }
      if (res?.message?.email) {
        setError("email", { message: res?.message?.email });
      }
      if (res?.message?.contact_no) {
        setError("contact_no", { message: res?.message?.contact_no });
      }
    }
  };

  const handleEdit = async () => {
    setEditStatus(!editStatus);
    getUser();
  };

  // const [userData, setUserData] = useState();
  const getUser = async () => {
    const res = await UserGet();
    if (res?.success) {
      setUserData(res.data);

      reset(res?.data);
      setSelectedDate(res?.data?.dob ? new Date(res.data.dob) : null);
      setProfileImage(res?.data?.profileImage); // Set profile image from API response
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    setProfileImage(URL.createObjectURL(file));
  };

  return (
    <>
      <section className="account-information my-account my-account-main ">
        <div className="container">
          <div className="heading_holder">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={"/"}>Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={"/information"}>Information</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                <Link to={"/information"}>Account Information </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <div className="back-text-holder">
            <Link to={"/information"}>
              <p>Back</p>
            </Link>
          </div>

          <MyAccount />

          <div>
            <h2 className="title">Account Information</h2>
          </div>

          <div className="form-section">
            <div className="row justify-content-center">
              <div className="col-xxl-5 col-lg-6 mb-3">
                <div className="account-form">
                  <div className="row mb-3">
                    <div className="col-md-10 col-10">
                      <h4 className="form-title">Account Details</h4>
                    </div>
                    <div className="col-md-2 col-2 text-end">
                      <div>
                        <h6 className="edit" onClick={handleEdit}>
                          Edit
                        </h6>
                      </div>
                    </div>
                  </div>
                  <Form>
                    <Col lg={12}>
                      <div className="image-preview-container mt-3 text-center">
                        {typeof getValues("image") === "string" ? (
                          <img
                            src={IMG_URL + getValues("image")}
                            alt="Preview"
                            className="image-preview rounded"
                            style={{ radius: "100px", height: "100px" }}
                          />
                        ) : (
                          imageFile &&
                          imageFile?.length > 0 && (
                            <img
                              src={URL?.createObjectURL(imageFile[0])}
                              alt="Preview"
                              className="image-preview rounded"
                              style={{ borderRadius: "100px", height: "100px" }}
                            />
                          )
                        )}
                      </div>
                    </Col>

                    <Col lg={12}>
                      <div className="main-form-section mt-3 mb-3">
                        <Row className="justify-content-center">
                          <Col lg={4}>
                            <Form.Label column sm="4" className="label-set">
                              Profile*
                            </Form.Label>
                          </Col>

                          <Col lg={8}>
                            <Form.Group>
                              <Form.Control
                                className={classNames("", {
                                  "is-invalid": errors?.image,
                                })}
                                type="file"
                                {...register("image", {})}
                                accept=".jpg, .jpeg, .png"
                                multiple={false}
                              />
                            </Form.Group>
                            {errors.image && (
                              <span className="text-danger">
                                {errors.image.message}
                              </span>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </Col>

                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" className="label-set">
                        Name*
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          disabled={editStatus}
                          type="text"
                          name="first_name"
                          placeholder="Enter First Name"
                          {...register("first_name", {
                            required: "First name required",
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.first_name && !editStatus,
                            "is-valid": getValues("first_name") && !editStatus,
                          })}
                          onKeyDown={(event) => {
                            if (!RegxExpression.name.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                        {errors?.first_name && (
                          <div className="mt-md-0 mt-sm-3 mt-3">
                            <sup className="text-danger  ">
                              {errors?.first_name?.message}
                            </sup>
                          </div>
                        )}
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" className="label-set">
                        Mobile Number*
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          disabled={editStatus}
                          className={classNames("", {
                            "is-invalid": errors?.contact_no && !editStatus,
                            "is-valid": getValues("contact_no") && !editStatus,
                          })}
                          type="text"
                          name="contact_no"
                          placeholder="Enter Mobile Number"
                          {...register("contact_no", {
                            required: "Field is required",
                            minLength: {
                              value: 10,
                              message:
                                "Number should be at least 10 characters",
                            },
                          })}
                          onKeyDown={(event) => {
                            if (!RegxExpression.phone.test(event.key)) {
                              if (event.key !== "Backspace") {
                                event.preventDefault();
                              }
                            }
                          }}
                          maxLength={10}
                        />
                        {errors?.contact_no && (
                          <div className="mt-md-0 mt-sm-3 mt-3">
                            <sup className="text-danger  ">
                              {errors?.contact_no?.message}
                            </sup>
                          </div>
                        )}
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" className="label-set">
                        Date Of Birth
                      </Form.Label>
                      <Col sm="8">
                        <Controller
                          control={control}
                          name="dob"
                          rules={{ required: "Field is required" }}
                          render={({ field }) => (
                            <DatePicker
                              selected={
                                field.value ? new Date(field.value) : null
                              }
                              onChange={(date) => {
                                field.onChange(format(date, "yyyy-MM-dd"));
                                setSelectedDate(date);
                              }}
                              dateFormat="yyyy-MM-dd"
                              placeholderText="Select Date"
                              maxDate={new Date()}
                              className={classNames("form-control", {
                                "is-invalid": errors?.dob && !editStatus,
                                "is-valid": getValues("dob") && !editStatus,
                              })}
                              disabled={editStatus}
                            />
                          )}
                        />
                        {errors?.dob && (
                          <div className="mt-md-0 mt-sm-3 mt-3">
                            <sup className="text-danger">
                              {errors?.dob?.message}
                            </sup>
                          </div>
                        )}
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" className="label-set">
                        Email
                      </Form.Label>
                      <Col sm="8">
                        <div
                          style={{
                            position: "relative",
                          }}
                        >
                          <Form.Control
                            disabled={editStatus}
                            type="text"
                            name="email"
                            placeholder="Enter Email"
                            {...register("email", {
                              required: "Email is required",
                              pattern: {
                                value: RegxExpression.email,
                                message: "Invalid email address",
                              },
                            })}
                            className={classNames("", {
                              "is-invalid": errors?.email && !editStatus,
                              "is-valid": getValues("email") && !editStatus,
                            })}
                          />
                          {errors?.email && (
                            <div className="mt-md-0 mt-sm-3 mt-3">
                              <sup className="text-danger  ">
                                {errors?.email?.message}
                              </sup>
                            </div>
                          )}
                          <div
                            style={{
                              position: "absolute",
                              top: "50%",
                              right: "10px",
                              transform: "translateY(-50%)",
                            }}
                          >
                            <span style={{ color: "green", fontSize: "1.2em" }}>
                              ✔
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Form.Group>

                    {!editStatus && (
                      <div className="btn-holder text-center">
                        <Common_Button
                          className={"SubmitBtn"}
                          text={"Update"}
                          onClick={handleSubmit(onSubmit)}
                        />
                      </div>
                    )}
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <UpdateSuccess
        show={modalShow}
        onHide={() => setModalShow(false)}
        text={"Data Updated Successfully"}
      />
    </>
  );
}

export default Information;

// import React, { useState } from "react";
// import "./Information.css";
// import { Col, Container, Form, Row, Breadcrumb } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import Common_Button from "../../../Common-Component/Common_Button/Common_Button";
// import Successfull_Modal from "../../../Common_modal/Successfull_Modal/Successfull_Modal";
// import DatePicker from "react-datepicker";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPlus } from "@fortawesome/free-solid-svg-icons";

// function Information() {
//   const [startDate, setStartDate] = useState(null); // State to manage date
//   const [modalShow, setModalShow] = useState(false);
//   const [isEditMode, setIsEditMode] = useState(false); // State to manage edit mode
//   const [profileImage, setProfileImage] = useState(
//     process.env.PUBLIC_URL + "/assets/Image/My_Profile/swapnil.png"
//   ); // State to manage profile image

//   const hideModalWithDelay = () => {
//     setTimeout(() => {
//       window.location.href = "/account-information"; // Change window location to root route ("/")
//       setModalShow(false);
//     }, 3000); // Change the delay time as needed (3000 milliseconds = 3 seconds)
//   };

//   const handleImageChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       const imageUrl = URL.createObjectURL(file);
//       setProfileImage(imageUrl);
//     }
//   };

//   return (
//     <>
//       <section className="account-information my-account ">
//         <div className="container">
//           <div className="heading_holder">
//             <Breadcrumb>
//               <Breadcrumb.Item>
//                 <Link to={"/"}>Home</Link>
//               </Breadcrumb.Item>
//               <Breadcrumb.Item>
//                 <Link to={"/information"}>My account</Link>
//               </Breadcrumb.Item>
//               <Breadcrumb.Item active>
//                 <Link to={"/information"}>Account Information </Link>
//               </Breadcrumb.Item>
//             </Breadcrumb>
//           </div>

//           <div className="back-text-holder">
//             <Link to={"/my-account"}>
//               <p>Back</p>
//             </Link>
//           </div>

//           <div>
//             <h2 className="title">Account Information</h2>
//           </div>

//           <div className="form-section">
//             <div className="row justify-content-center">
//               <div className="col-xxl-5 col-lg-6 mb-3">
//                 <div className="account-form">
//                   <div className="row mb-3">
//                     <div className="col-md-4 col-10">
//                       <h4 className="form-title">Account Details</h4>
//                     </div>

//                     <div className="col-md-4 text-center">
//                       <div className="profile-img-icon-holder">
//                         <div className="img-holder">
//                           <img className="user-prof-img" src={profileImage} />
//                         </div>
//                         <div className="plus-icon-holder">
//                           <input
//                             type="file"
//                             accept="image/*"
//                             style={{ display: "none" }}
//                             id="file-input"
//                             onChange={handleImageChange}
//                           />
//                           <label htmlFor="file-input">
//                             <FontAwesomeIcon
//                               className="plus-icon"
//                               icon={faPlus}
//                             />
//                           </label>
//                         </div>
//                       </div>
//                     </div>

//                     <div className="col-md-4 col-2 text-end">
//                       <Link to={"#"} onClick={() => setIsEditMode(true)}>
//                         <h6 className="edit">Edit</h6>
//                       </Link>
//                     </div>
//                   </div>
//                   <Form>
//                     <Form.Group
//                       as={Row}
//                       className="mb-3"
//                       controlId="formPlaintextPassword"
//                     >
//                       <Form.Label column sm="4" className="label-set">
//                         Name*
//                       </Form.Label>
//                       <Col sm="8">
//                         <Form.Control type="text" placeholder="Enter Name" />
//                       </Col>
//                     </Form.Group>

//                     <Form.Group
//                       as={Row}
//                       className="mb-3"
//                       controlId="formPlaintextPassword"
//                     >
//                       <Form.Label column sm="4" className="label-set">
//                         Mobile Number*
//                       </Form.Label>
//                       <Col sm="8">
//                         <Form.Control
//                           type="phone-number"
//                           placeholder="Enter Mobile Number"
//                         />
//                       </Col>
//                     </Form.Group>
//                     <Form.Group
//                       as={Row}
//                       className="mb-3"
//                       controlId="formPlaintextPassword"
//                     >
//                       <Form.Label column sm="4" className="label-set">
//                         Email
//                       </Form.Label>
//                       <Col sm="8">
//                         <Form.Control
//                           type="text"
//                           placeholder="Enter Email Id"
//                         />
//                       </Col>
//                     </Form.Group>

//                     <Form.Group
//                       as={Row}
//                       className="mb-3"
//                       controlId="formPlaintextPassword"
//                     >
//                       <Form.Label column sm="4" className="label-set">
//                         Date Of Birth*
//                       </Form.Label>
//                       <Col sm="8">
//                         <DatePicker
//                           className="form-control"
//                           selected={startDate}
//                           onChange={(date) => setStartDate(date)}
//                           placeholderText="DD/MM/YYYY"
//                         />
//                       </Col>
//                     </Form.Group>

//                     {isEditMode && (
//                       <div className="btn-holder text-center">
//                         <Common_Button
//                           className={"SubmitBtn"}
//                           text={"Update"}
//                           onClick={() => {
//                             setModalShow(true);
//                             hideModalWithDelay();
//                           }}
//                         />
//                       </div>
//                     )}
//                   </Form>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//       <Successfull_Modal
//         message="Account Details Updated Successfully"
//         show={modalShow}
//         onHide={() => setModalShow(false)}
//       />
//     </>
//   );
// }

// export default Information;
