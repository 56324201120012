import React, { useState, useEffect, useContext } from "react";
import "./ForgotPassword.css";
import { Breadcrumb, Button, Container, Form, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Password_Modal from "../../../Common_modal/Password_Modal/Password_Modal";
import Successfull_Modal from "../../../Common_modal/Successfull_Modal/Successfull_Modal";
import Common_Button from "../../../Common-Component/Common_Button/Common_Button";
import InputGroup from "react-bootstrap/InputGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import PasswordChecklist from "react-password-checklist";
import classNames from "classnames";

import { ChangePassword } from "../../../../utils/apis/myAccount/myAccount";

function ForgotPassword() {
  const [modalShow, setModalShow] = useState(false);

  const hideModalWithDelay = () => {
    setTimeout(() => {
      navigate("/my-account");
      setModalShow(false);
    }, 3000);
  };

  const navigate = useNavigate();
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
  } = useForm();

  const onSubmit = async (data) => {
    if (password === passwordAgain) {
      const res = await ChangePassword(data);
      if (res?.success) {
        setModalShow(true);
        hideModalWithDelay();
      } else {
        if (res?.message?.current_password) {
          setError("current_password", {
            message: res?.message?.current_password,
          });
        }
      }
    } else {
      setError("current_password", { message: "Password must match" });
    }
  };

  const [showPasswordCurrent, setShowPasswordCurrent] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordOne, setShowPasswordOne] = useState(false);
  const [iconOne, setIconOne] = useState(faEye);
  const [iconTwo, setIconTwo] = useState(faEye);
  const [iconCurrent, setIconCurrent] = useState(faEye);

  const togglePasswordVisibility = (field) => {
    if (field === "password") {
      setShowPassword(!showPassword);
      setIconOne(showPassword ? faEyeSlash : faEye);
    } else if (field === "reenterPassword") {
      setShowPasswordOne(!showPasswordOne);
      setIconTwo(showPasswordOne ? faEyeSlash : faEye);
    } else if (field === "current_password") {
      setShowPasswordCurrent(!showPasswordCurrent);
      setIconCurrent(showPasswordCurrent ? faEyeSlash : faEye);
    }
  };
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  return (
    <>
      <section className="changepassword-section my-account my-account-main">
        <div className="container">
          <div className="heading_holder">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={"/"}>Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={"/information"}>Information</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                <Link to={"/change-password"}>Change Password</Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <div className="back-text-holder">
            <Link to={"/information"}>
              <p>Back</p>
            </Link>
          </div>

          <div>
            <h2 className="title">Change Password</h2>
          </div>

          <div className="row">
            <div className=" col-lg-6 col-md-10 col-sm-10 col-12 mx-auto">
              <div className="passwordmain">
                <div className="pass-heading">
                  <h2>Change Your Password</h2>
                </div>
                <div className="formpart">
                  <Form>
                    <InputGroup className="mb-3">
                      <Form.Control
                        type={showPasswordCurrent ? "text" : "password"}
                        placeholder="Enter Current Password*"
                        {...register("current_password", {
                          required: "Current password is required",
                        })}
                      />
                      <InputGroup.Text id="basic-addon2">
                        <FontAwesomeIcon
                          icon={iconCurrent}
                          className="eye-icon"
                          onClick={() =>
                            togglePasswordVisibility("current_password")
                          }
                        />
                      </InputGroup.Text>
                    </InputGroup>

                    <InputGroup className="mb-3">
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter Password*"
                        {...register("new_password", {
                          required: "Password is required",
                          minLength: {
                            value: 8,
                            message:
                              "Password must be at least 8 characters long",
                          },
                          pattern: {
                            value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                            message:
                              "Password must contain at least one uppercase letter, one lowercase letter, and one number",
                          },
                          validate: (value) =>
                            value !== getValues("current_password") ||
                            "New password must be different from current password",
                        })}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <InputGroup.Text id="basic-addon2">
                        <FontAwesomeIcon
                          icon={iconOne}
                          className="eye-icon"
                          onClick={() => togglePasswordVisibility("password")}
                        />
                      </InputGroup.Text>
                    </InputGroup>

                    <InputGroup className="mb-3">
                      <Form.Control
                        type={showPasswordOne ? "text" : "password"}
                        placeholder="Re-Enter Password*"
                        {...register("re_password", {
                          required: "Re-Password is required",
                          validate: (value) =>
                            value === getValues("new_password") ||
                            "Passwords do not match",
                        })}
                        onChange={(e) => setPasswordAgain(e.target.value)}
                      />
                      <InputGroup.Text id="basic-addon2">
                        <FontAwesomeIcon
                          icon={iconTwo}
                          className="eye-icon"
                          onClick={() =>
                            togglePasswordVisibility("reenterPassword")
                          }
                        />
                      </InputGroup.Text>
                    </InputGroup>

                    {errors?.current_password && (
                      <sup className="text-danger">
                        {errors?.current_password?.message}
                      </sup>
                    )}
                    {errors?.new_password && (
                      <sup className="text-danger">
                        {errors?.new_password?.message}
                      </sup>
                    )}
                    {errors?.re_password && (
                      <sup className="text-danger">
                        {errors?.re_password?.message}
                      </sup>
                    )}

                    {password && (
                      <PasswordChecklist
                        rules={[
                          "minLength",
                          "specialChar",
                          "number",
                          "capital",
                          "match",
                        ]}
                        minLength={8}
                        value={password}
                        valueAgain={passwordAgain}
                        onChange={(isValid) => {}}
                        style={{
                          fontSize: "10px",
                          padding: "4px",
                          margin: "0",
                        }}
                      />
                    )}
                  </Form>

                  <Common_Button
                    onClick={handleSubmit(onSubmit)}
                    text="Submit"
                    className="submitbtn"
                  />
                  <Link to={"/my-account"}>
                    <p className="cancel">Cancel</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Successfull_Modal
        message=" Your password has been changed successfully "
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default ForgotPassword;
